<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入用户姓名" suffix-icon="el-icon-search" v-model="nickname"></el-input>
      <el-input style="width: 200px" placeholder="请输入工单号" suffix-icon="el-icon-search" v-model="reconum"></el-input>
      <el-input style="width: 200px" placeholder="请输入车间" suffix-icon="el-icon-search" v-model="linename"></el-input>
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
      <el-table-column prop="reconum" label="工单号"></el-table-column>
      <el-table-column prop="userName" label="姓名"></el-table-column>
      <el-table-column prop="lineName" label="车间"></el-table-column>
      <el-table-column prop="workHour" label="核算工时"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间"></el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="">
          <el-input v-model="form.reconum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.userName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.lineName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.workHour" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-date-picker v-model="form.updateTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "LogWorkHour",
  data() {
    return {
      tableData: [],
      startDate : "",
      endDate : "",
      nickname : "",
      linename : "",
      reconum : "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      baseURLdata: request.defaults.baseURL
    }
  },
  created() {
    this.loadState()
    this.load()
  },
  methods: {
    load() {
      this.request.get("/logWorkHour/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          nickname: this.nickname,
          linename: this.linename,
          reconum: this.reconum,
          startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
          endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : ''
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.saveState()
    },
    save() {
      this.request.post("/logWorkHour", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    saveState() {
      const state = {
        nickname: this.nickname,
        linename: this.linename,
        reconum: this.reconum,
        startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
        endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      sessionStorage.setItem('logWorkHourState', JSON.stringify(state));
    },
    loadState() {
      const savedState = sessionStorage.getItem('logWorkHourState');
      if (savedState) {
        const { nickname, linename, reconum, startDate, endDate, pageNum, pageSize } = JSON.parse(savedState);
        this.nickname = nickname || '';
        this.linename = linename || '';
        this.reconum = reconum || '';
        this.startDate = startDate ? new Date(startDate) : null;
        this.endDate = endDate ? new Date(endDate) : null;
        this.pageNum = pageNum || 1;
        this.pageSize = pageSize || 10;
      }
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    del(id) {
      this.request.delete("/logWorkHour/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/logWorkHour/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.nickname = ""
      this.linename = ""
      this.reconum = ""
      this.startDate = ""
      this.endDate = ""
      this.load()
      sessionStorage.removeItem('logWorkHourState')
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      // 获取筛选条件的值
      const params = new URLSearchParams();
      if (this.nickname) {
        params.append('nickname', this.nickname);
      }
      if (this.reconum) {
        params.append('reconum', this.reconum);
      }
      if (this.linename) {
        params.append('linename', this.linename);
      }
      if (this.startDate) {
        params.append('startDate', this.startDate ? `${this.startDate.getFullYear()}-${(this.startDate.getMonth() + 1).toString().padStart(2, '0')}-${this.startDate.getDate().toString().padStart(2, '0')}` : '');
      }
      if (this.endDate) {
        params.append('endDate', this.endDate ? `${this.endDate.getFullYear()}-${(this.endDate.getMonth() + 1).toString().padStart(2, '0')}-${this.endDate.getDate().toString().padStart(2, '0')}` : '');
      }

      // 打开新窗口进行导出
      window.open(this.baseURLdata + "/logWorkHour/export?" + params.toString());
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
